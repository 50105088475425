import React, { Component } from 'react';
import { Consumer } from 'store/createContext';
import Layout from 'components/layout';
import Login from 'components/login';
import DealerSubmit from 'components/DealerSubmit';

class ListCar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullform: false,
      editNid: '',
      uuid: '',
      carData: null,
    };
    this.fullform = this.fullform.bind(this);
  }

  componentDidMount() {
    if (window.history.state) {
      if (window.history.state.edit) {
        this.setState({
          fullform: true,
        });
      }
      if (window.history.state.nid) {
        this.setState({
          editNid: window.history.state.nid,
        });
      }
      if (window.history.state.carData) {
        this.setState({
          carData: window.history.state.carData,
        });
      }
      if (window.history.state.uuid) {
        this.setState({
          uuid: window.history.state.uuid,
        });
      }
    }
  }

  fullform() {
    this.setState({
      fullform: true,
    });
  }

  render() {
    const { editNid, carData, uuid } = this.state;
    return (
      <Consumer>
        {({ isLoggedIn, toggleLoginStatus }) => (
          <Layout>
            <div className="container mt-5 mb-5">
              {!this.state.fullform && (
                <div
                  className={`row ${
                    !this.state.fullform ? 'shown' : 'is-hidden'
                  }`}
                >
                  <div className="col-12 text-center">
                    <h1>
                      List a car with Sell it on <strong>for Dealers</strong>
                    </h1>
                  </div>
                  <div className="col-12 col-lg-8 offset-0 offset-lg-2 text-center mt-4">
                    {!isLoggedIn && (
                      <p>
                        To join hundreds of UK dealers using Sell it on to list
                        cars simply enter your email address below to continue
                      </p>
                    )}
                    {isLoggedIn && (
                      <p>
                        To list a car with us start by entering the cars
                        registration below
                      </p>
                    )}
                  </div>
                </div>
              )}
              <div className="mt-5">
                {!isLoggedIn && (
                  <div className="row">
                    <Login toggleLoginStatus={toggleLoginStatus} />
                  </div>
                )}
                {isLoggedIn && (
                  <DealerSubmit
                    fullform={this.fullform}
                    editNid={editNid}
                    nidUuid={uuid}
                    carData={carData}
                  />
                )}
              </div>
            </div>
          </Layout>
        )}
      </Consumer>
    );
  }
}

export default ListCar;
