import 'date-fns';
import React, { Component } from 'react';
import axios from 'axios';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { get_backend_select_options } from 'helpers/backend';

const formGroupWrapper = {
  backgroundColor: '#fff',
  padding: '30px',
  borderRadius: '10px',
};

class ServiceHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceHistoryOptions: [],
      numServicesOptions: [],
      mainDealerServicesOptions: [],
      camBeltChangedOptions: [],
      serviceHistory: '',
      numServices: '',
      mainDealerServices: '',
      lastServiceDate: null,
      camBeltChanged: '',
    };
    this.buildSelectOptions = this.buildSelectOptions.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    axios
      .all([
        get_backend_select_options('field_what_is_the_service_histor'),
        get_backend_select_options('field_how_many_services'),
        get_backend_select_options('field_how_many_main_dealer'),
        get_backend_select_options('field_has_the_cambelt_ever_been'),
      ])
      .then(
        axios.spread(
          (
            serviceHistoryOptions,
            numServicesOptions,
            mainDealerServicesOptions,
            camBeltChangedOptions
          ) => {
            this.buildSelectOptions(
              serviceHistoryOptions.data.settings.allowed_values,
              'serviceHistoryOptions'
            );
            this.buildSelectOptions(
              numServicesOptions.data.settings.allowed_values,
              'numServicesOptions'
            );
            this.buildSelectOptions(
              mainDealerServicesOptions.data.settings.allowed_values,
              'mainDealerServicesOptions'
            );
            this.buildSelectOptions(
              camBeltChangedOptions.data.settings.allowed_values,
              'camBeltChangedOptions'
            );
          }
        )
      );
  }

  buildSelectOptions(options, stateValue) {
    {
      Object.keys(options).map(key => {
        this.setState({
          [stateValue]: {
            ...this.state[stateValue],
            [key]: options[key],
          },
        });
      });
    }
  }

  handleSelectChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChangeDate(date) {
    if (date != null) {
      this.setState({
        lastServiceDate: new Date(date),
      });
    } else {
      this.setState({
        lastServiceDate: null,
      });
    }
  }

  render() {
    return (
      <div className="mt-4" style={formGroupWrapper}>
        <h3>Service History</h3>
        <div className="mt-4">
          <p className="mb-1">What is the service history of the car?</p>
          <FormControl variant="filled">
            <InputLabel htmlFor="serviceHistory">
              Select service history
            </InputLabel>
            <Select
              value={this.state.serviceHistory}
              onChange={e => {
                this.handleSelectChange(e);
                this.props.handleSelectChange(e);
              }}
              inputProps={{
                name: 'serviceHistory',
              }}
            >
              {Object.keys(this.state.serviceHistoryOptions).map(key => {
                return (
                  <MenuItem key={key} value={key}>
                    {this.state.serviceHistoryOptions[key]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        {this.state.serviceHistory == 'Full or partial service history' && (
          <React.Fragment>
            <div className="mt-4">
              <p className="mb-1">
                How many services are recorded in the service book?
              </p>
              <FormControl variant="filled">
                <InputLabel htmlFor="numServices">
                  Select number of services
                </InputLabel>
                <Select
                  value={this.state.numServices}
                  onChange={e => {
                    this.handleSelectChange(e);
                    this.props.handleSelectChange(e);
                  }}
                  inputProps={{
                    name: 'numServices',
                  }}
                >
                  {Object.keys(this.state.numServicesOptions).map(key => {
                    return (
                      <MenuItem key={key} value={key}>
                        {this.state.numServicesOptions[key]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="mt-4">
              <p className="mb-1">
                How many main dealer services are recorded in the service book?
              </p>
              <FormControl variant="filled">
                <InputLabel htmlFor="mainDealerServices">
                  Select main dealer services
                </InputLabel>
                <Select
                  value={this.state.mainDealerServices}
                  onChange={e => {
                    this.handleSelectChange(e);
                    this.props.handleSelectChange(e);
                  }}
                  inputProps={{
                    name: 'mainDealerServices',
                  }}
                >
                  {Object.keys(this.state.mainDealerServicesOptions).map(
                    key => {
                      return (
                        <MenuItem key={key} value={key}>
                          {this.state.mainDealerServicesOptions[key]}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </FormControl>
            </div>
            <div className="mt-4">
              <p className="mb-1">When was the vehicle last serviced?</p>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id="date-picker-dialog"
                  label="Last service date"
                  format="dd/MM/yyyy"
                  value={this.state.lastServiceDate}
                  onChange={e => {
                    this.onChangeDate(e);
                    this.props.onChangeDate(e);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  variant="dialog"
                  disableFuture={true}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="mt-4">
              <p className="mb-1">What was the mileage at the last service?</p>
              <TextField
                type="number"
                id="filled-name"
                label="Mileage"
                name="mileageAtLastService"
                onChange={this.props.handleTextChange}
                variant="filled"
              />
            </div>
            <div className="mt-4">
              <p className="mb-1">Has the cambelt ever been changed?</p>
              <FormControl variant="filled">
                <InputLabel htmlFor="camBeltChanged">Select option</InputLabel>
                <Select
                  value={this.state.camBeltChanged}
                  onChange={e => {
                    this.handleSelectChange(e);
                    this.props.handleSelectChange(e);
                  }}
                  inputProps={{
                    name: 'camBeltChanged',
                  }}
                >
                  {Object.keys(this.state.camBeltChangedOptions).map(key => {
                    return (
                      <MenuItem key={key} value={key}>
                        {this.state.camBeltChangedOptions[key]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default ServiceHistory;
