import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import { number_plate_exists } from 'helpers/backend';

const LoadingOverlay = styled.div`
  position: absolute;
  z-index: 10;
  background-color: rgba(243, 243, 243, 0.8);
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  border-radius: 10px;
`;

const blueIcon = {
  color: '#2cabde',
};

const LoaderInner = styled.div`
  padding: 100px;
`;

const PlateField = styled.input`
  font-family: 'UKNumberPlate';
  -webkit-appearance: none;
  width: 100%;
  background-color: #f7b733;
  border-style: none;
  border-color: #f7b733;
  box-shadow: none;
  font-size: 40px;
  line-height: 40px;
  height: 60px;
  padding-bottom: 0;
  padding-top: 0;
  margin-bottom: 20px;
  text-align: center;
  padding: 0 15px;
  border-radius: 8px;
  transition-duration: 0.15s;
  transition-property: box-shadow;
  transition-timing-function: ease-in-out;
  outline: 0;
  ::placeholder {
    color: #42413d;
  }
  &:focus {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  }
`;

const SubmitBtn = styled.button`
  -webkit-appearance: none;
  width: 100%;
  background-color: #2cabde;
  border: none;
  border-radius: 8px;
  color: #fff;
  opacity: 1;
  cursor: pointer;
  font-weight: 600;
  font-size: 25px;
  padding: 8px;
  transition-duration: 0.15s;
  transition-property: all;
  transition-timing-function: ease-in-out;
  box-shadow: none;
  &:hover {
    opacity: 0.9;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  }
`;

const InvalidWrapper = styled.div`
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  color: #42413d;
  left: 50%;
  min-width: 200px;
  padding: 7px 15px;
  position: absolute;
  top: 0;
  transform: translate(-50%, -100%);
  transition: 0.15s ease-in-out;
  z-index: 1000;
  &:before {
    border: 8px solid transparent;
    border-top: 9px solid #fff;
    bottom: -17px;
    content: '';
    height: 1px;
    left: calc(50% - 4px);
    position: absolute;
    width: 1px;
  }
`;

const InvalidText = styled.p`
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

class SearchVrm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plate: '',
      error: '',
      loading: false,
    };
    this.renderErrorFor = this.renderErrorFor.bind(this);
    this.hasErrorFor = this.hasErrorFor.bind(this);
  }

  handleFieldChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  hasErrorFor(field) {
    return !!this.state.error;
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <InvalidWrapper className="invalid">
          <InvalidText>{this.state.error}</InvalidText>
        </InvalidWrapper>
      );
    }
  }

  handleNumberPlateLookup(event) {
    event.preventDefault();
    const reg = new RegExp(
      '(^[A-Z]{2}[0-9]{2}s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)'
    );
    const PlateValidate = this.state.plate.replace(/\s+/g, '');
    this.setState({
      plate: PlateValidate,
      loading: true,
    });
    if (this.state.plate && reg.test(PlateValidate.toUpperCase())) {
      number_plate_exists(this.state.plate).then(res => {
        if (res.data.length == 0) {
          axios
            .get(
              `https://uk1.ukvehicledata.co.uk/api/datapackage/VehicleData?v=2&api_nullitems=1&auth_apikey=${
                process.env.UKREG_LOOKUP
              }&user_tag=&key_VRM=` + PlateValidate
            )
            .then(res => {
              var basicAuthCredential = `${process.env.BACKEND_USERNAME}:${
                process.env.BACKEND_PASSWORD
              }`;
              var bace64 = btoa(basicAuthCredential);
              if (res.data.Response.StatusCode == 'Success') {
                const carData = res.data.Response;
                // Check if make exists in system
                const carMake =
                  res.data.Response.DataItems.VehicleRegistration.Make;
                axios
                  .get(
                    `${
                      process.env.BACKEND_URL
                    }/taxonomy/vehicle/make?term_name=${
                      res.data.Response.DataItems.VehicleRegistration.Make
                    }&_format=hal_json`,
                    {
                      headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/hal+json',
                        'X-CSRF-Token': `${process.env.BACKEND_CSRF_TOKEN}`,
                        Authorization: 'Basic ' + bace64,
                      },
                    }
                  )
                  .then(res => {
                    if (res.data.length == 0) {
                      // Create make as taxonomy term in backend
                      var basicAuthCredential = `${
                        process.env.BACKEND_USERNAME
                      }:${process.env.BACKEND_PASSWORD}`;
                      var bace64 = btoa(basicAuthCredential);
                      axios
                        .post(
                          `${
                            process.env.BACKEND_URL
                          }/entity/taxonomy_term?_format=hal_json`,
                          JSON.stringify({
                            _links: {
                              type: {
                                href: `${
                                  process.env.BACKEND_URL_NON_HTTPS
                                }/rest/type/taxonomy_term/make`,
                              },
                            },
                            vid: [
                              {
                                target_id: 'make',
                              },
                            ],
                            name: [
                              {
                                value: carMake,
                                lang: 'en',
                              },
                            ],
                          }),
                          {
                            headers: {
                              Accept: 'application/json',
                              'Content-Type': 'application/hal+json',
                              'X-CSRF-Token': `${
                                process.env.BACKEND_CSRF_TOKEN
                              }`,
                              Authorization: 'Basic ' + bace64,
                            },
                          }
                        )
                        .then(res => {
                          this.props.makeid(res.data.tid[0].value);
                          this.props.data(carData);
                        });
                    } else {
                      this.props.makeid(res.data[0].tid[0].value);
                      this.props.data(carData);
                    }
                  });
              } // end succces of finding car details
              else {
                this.setState({
                  loading: false,
                  error: 'Sorry, we could not find this car',
                });
              }
            });
        } else {
          this.setState({
            loading: false,
            error: 'Sorry, something went wrong. Please give us a call',
          });
        }
      });
    } else {
      this.setState({
        loading: false,
        error: 'Please enter a valid UK registration',
      });
    }
  }

  render() {
    const { plate, searchStatus, loading } = this.state;
    return (
      <div className="row position-relative">
        {loading && (
          <LoadingOverlay className="d-flex align-items-center justify-content-center">
            <LoaderInner className="text-center">
              <FontAwesomeIcon
                className="fa-spin fa-5x"
                style={blueIcon}
                icon={faCircleNotch}
              />
            </LoaderInner>
          </LoadingOverlay>
        )}
        <div className="col-12 col-lg-5 mx-lg-auto col-md-8 mx-md-auto">
          <form onSubmit={this.handleNumberPlateLookup.bind(this)}>
            <div className="position-relative">
              <PlateField
                type="text"
                name="plate"
                placeholder="ENTER REG"
                value={plate}
                onChange={this.handleFieldChange.bind(this)}
                disabled={searchStatus}
              />
              {this.renderErrorFor('plate')}
              {!searchStatus && <SubmitBtn>Find Car</SubmitBtn>}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default SearchVrm;
