import React, { Component } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { navigate } from 'gatsby';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';

import ResetPassword from './ResetPassword';

import {
  login_user,
  roles_from_email,
  get_first_name_last_name,
} from 'helpers/backend';

const ForgotPassword = styled.button`
  background: transparent
  border: 0
  padding: 0
  color: #42413D
  font-size: 1rem
  &:hover {
    color: #98968b;
    cursor: pointer;
  }
`;

const CssTextField = withStyles({
  root: {
    width: '100%',
    color: '#42413D',
    '& label.Mui-focused': {
      color: '#42413d5c',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderWidth: '2px',
        borderColor: '#42413D',
      },
      '&:hover fieldset': {
        borderColor: '#42413d5c',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#42413d5c',
      },
      '& input:valid + fieldset': {
        borderColor: '#039869',
        borderWidth: 2,
      },
    },
  },
})(TextField);

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resetPassword: false,
      email: '',
      username: '',
      password: '',
      email_invalid: '',
      email_error: false,
      submitting: false,
      showPassword: false,
      enterPassword: false,
      sendLoginRequest: false,
      loginRedirect: this.props.loginRedirect
        ? this.props.loginRedirect
        : false,
    };
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleClickShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  handleMouseDownPassword(e) {
    e.preventDefault();
  }

  onBlur(e) {
    if (e.target.name == 'email') {
      const emailValid = e.target.value.match(
        /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
      );
      if (!emailValid) {
        this.setState({
          email_invalid: 'Please enter a valid email address',
          email_error: true,
        });
      } else {
        this.setState({
          email_invalid: '',
          email_error: false,
        });
      }
    }
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      submitting: true,
    });
    var basicAuthCredential = `${process.env.BACKEND_USERNAME}:${
      process.env.BACKEND_PASSWORD
    }`;
    var bace64 = btoa(basicAuthCredential);
    if (!this.state.sendLoginRequest) {
      axios
        .get(
          `${process.env.BACKEND_URL}/user/email/validation?mail=${
            this.state.email
          }&_format=hal_json`,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/hal+json',
              'X-CSRF-Token': `${process.env.BACKEND_CSRF_TOKEN}`,
              Authorization: 'Basic ' + bace64,
            },
          }
        )
        .then(res => {
          const emailValidation = res;
          roles_from_email(this.state.email).then(res => {
            const roles =
              res.data.length > 0
                ? res.data[0].roles_target_id.split(', ')
                : [];
            if (roles.includes('Customer')) {
              this.setState({
                submitting: false,
                emailIsNotDealer:
                  'Sorry, we cant log you in. Please try logging in at selliton.co.uk',
              });
            } else {
              if (emailValidation.data.length > 0) {
                this.setState({
                  sendLoginRequest: true,
                  enterPassword: true,
                  submitting: false,
                  username: emailValidation.data[0].name[0].value,
                });
              } else {
                navigate('/signup', {
                  state: {
                    email: this.state.email,
                  },
                });
              }
            }
          });
        });
    }
    if (this.state.sendLoginRequest) {
      login_user(this.state.username, this.state.password)
        .then(res => {
          const uid = res.data.current_user.uid;
          const csrfToken = res.data.csrf_token;
          const LogoutToken = res.data.logout_token;
          get_first_name_last_name(uid).then(res => {
            let appState = {
              csrf: csrfToken,
              logout_token: LogoutToken,
              uid: uid,
              email: btoa(this.state.email),
              fnt: btoa(res.data.field_first_name[0].value),
              lnt: btoa(res.data.field_last_name[0].value),
            };
            localStorage['appState'] = JSON.stringify(appState);
            this.props.toggleLoginStatus();
            if (this.state.loginRedirect) {
              navigate(`/${this.state.loginRedirect}`);
            }
          });
        })
        .catch(error => {
          if (error) {
            this.setState({
              submitting: false,
              login_error: 'Sorry, we didnt recognise your email and password',
            });
          }
        });
    }
  }

  handleChange() {
    this.setState({
      resetPassword: !this.state.resetPassword,
    });
  }

  render() {
    const {
      email_invalid,
      email_error,
      submitting,
      enterPassword,
      showPassword,
      password,
      login_error,
      emailIsNotDealer,
    } = this.state;
    const onBlur = this.onBlur;
    const handleSubmit = this.handleSubmit;
    const onChange = this.onChange;
    const handleClickShowPassword = this.handleClickShowPassword;
    const handleMouseDownPassword = this.handleMouseDownPassword;
    return (
      <React.Fragment>
        <ResetPassword
          open={this.state.resetPassword}
          email={this.state.email}
          handleChange={this.handleChange.bind(this)}
        />
        <div className="col-12 col-md-6 col-lg-4 offset-0 offset-md-3 m-lg-auto">
          <h2 className="text-center">Email address</h2>
          <form onSubmit={handleSubmit}>
            <CssTextField
              label="Email"
              name="email"
              type="email"
              margin="normal"
              variant="outlined"
              required
              error={email_error}
              onChange={onChange}
              onBlur={onBlur}
            />
            {email_invalid && (
              <p
                style={{ fontSize: '0.8rem' }}
                className="mt-1 mb-0 px-3 font-weight-bold text-danger"
              >
                {email_invalid}
              </p>
            )}
            {emailIsNotDealer && (
              <p className="mt-1 mb-0 px-3 font-weight-bold text-danger">
                {emailIsNotDealer}
              </p>
            )}
            {enterPassword && (
              <React.Fragment>
                <CssTextField
                  id="outlined-adornment-password"
                  variant="outlined"
                  margin="normal"
                  type={showPassword ? 'text' : 'password'}
                  label="Password"
                  name="password"
                  value={password}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={this.state.password_error}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <p className="text-right pt-2 mb-2">
                  <ForgotPassword
                    type="button"
                    onClick={e => this.setState({ resetPassword: true })}
                  >
                    Forgotten your password?
                  </ForgotPassword>
                </p>
                {login_error && (
                  <p className="text-center text-danger font-weight-bold">
                    {login_error}
                  </p>
                )}
              </React.Fragment>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="w-100 mt-3"
              disabled={submitting || email_error}
            >
              {submitting ? <CircularProgress /> : 'Continue'}
            </Button>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default Login;
