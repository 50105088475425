import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import SearchVrm from './SearchVrm';
import TitleData from './TitleData';
import BasicDetails from './form/BasicDetails';
import ServiceHistory from './form/ServiceHistory';
import Condition from './form/Condition';
import WheelsTryes from './form/WheelsTryes';
import Financial from './form/Financial';
import Images from './form/Images';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import { save_to_backend, send_thank_you_email } from 'helpers/backend';

const LoadingOverlay = styled.div`
  position: absolute;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  border-radius: 10px;
`;

const Submit = styled.button`
  width: 100%;
  background-color: #2cabde;
  border: none;
  border-radius: 8px;
  color: #fff;
  opacity: 1;
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
  padding: 10px;
  transition-duration: 0.15s;
  transition-property: all;
  transition-timing-function: ease-in-out;
  box-shadow: none   
    &:hover {
    opacity: 0.9;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  }
  :disabled {
    background-color: #a9a9a9;
    &:hover {
      opacity: 1;
      box-shadow: none;
      cursor: not-allowed;
    }
  }
`;

const blueIcon = {
  color: '#2cabde',
};

const LoaderInner = styled.div`
  padding: 100px;
`;

class DealerSubmit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backendNid: this.props.editNid ? this.props.editNid : '',
      nidUuid: this.props.nidUuid ? this.props.nidUuid : '',
      loading: false,
      carData: this.props.carData ? this.props.carData : null,
      motExpiry: '',
      makeid: '',
      field_basic_details: [],
      sectionone: this.props.editNid ? false : true,
      sectiontwo: this.props.editNid ? true : false,
    };
    this.updateCarData = this.updateCarData.bind(this);
    this.makeid = this.makeid.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleBasicDetailsChange = this.handleBasicDetailsChange.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.sectionOneSubmit = this.sectionOneSubmit.bind(this);
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (prevProps.carData != this.props.carData) {
      this.setState({
        carData: this.props.carData,
      });
    }
    if (prevProps.editNid != this.props.editNid) {
      this.setState({
        backendNid: this.props.editNid,
        sectionone: false,
        sectiontwo: true,
      });
    }
    if (prevProps.nidUuid != this.props.nidUuid) {
      this.setState({
        nidUuid: this.props.nidUuid,
      });
    }
    return null;
  }

  updateCarData(e) {
    this.setState({
      carData: e,
    });
    this.props.fullform();
  }

  makeid(e) {
    this.setState({
      makeid: e,
    });
  }

  handleTextChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSelectChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleBasicDetailsChange(e) {
    this.setState({
      field_basic_details: Object.keys(e),
    });
  }

  onChangeDate(date) {
    if (date != null) {
      this.setState({
        lastServiceDate: new Date(date),
      });
    } else {
      this.setState({
        lastServiceDate: null,
      });
    }
  }

  sectionOneSubmit(e) {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const uid = JSON.parse(localStorage['appState']).uid;

    axios
      .get(
        `https://uk1.ukvehicledata.co.uk/api/datapackage/MotHistoryData?v=2&api_nullitems=1&auth_apikey=${
          process.env.UKREG_LOOKUP
        }&user_tag=&key_VRM=${
          this.state.carData.DataItems.VehicleRegistration.Vrm
        }`
      )
      .then(res => {
        if (res.data.Response.DataItems.MotHistory) {
          if (res.data.Response.DataItems.MotHistory.RecordCount > 0) {
            var MotExpiryDateRaw =
              res.data.Response.DataItems.MotHistory.RecordList[0].ExpiryDate;
            var MotExpiryDateFormatted = MotExpiryDateRaw.split('/')
              .reverse()
              .join('-');
            this.setState({
              motExpiry: MotExpiryDateFormatted,
            });
          }
        }

        axios
          .get(
            `https://uk1.ukvehicledata.co.uk/api/datapackage/ValuationData?v=2&api_nullitems=1&auth_apikey=${
              process.env.UKREG_LOOKUP
            }&user_tag=&key_VRM=${
              this.state.carData.DataItems.VehicleRegistration.Vrm
            }&key_mileage=` + this.state.mileage
          )
          .then(res => {
            const TradeAverage = res.data.Response.DataItems.ValuationList
              ? res.data.Response.DataItems.ValuationList.TradeAverage
              : 0;
            const DealerForecourt = res.data.Response.DataItems.ValuationList
              ? res.data.Response.DataItems.ValuationList.DealerForecourt
              : 0;
            const valueCalc = parseFloat(TradeAverage);
            const valueCalcFinal = valueCalc - valueCalc * 0.015;
            save_to_backend(
              this.state,
              uid,
              DealerForecourt,
              TradeAverage,
              valueCalcFinal
            ).then(res => {
              send_thank_you_email(res.data, 'initial');
              setTimeout(() => {
                this.setState({
                  backendNid: res.data.nid[0].value,
                  nidUuid: res.data.uuid[0].value,
                  loading: false,
                  sectionone: false,
                  sectiontwo: true,
                });
              }, 500);
            });
          });
      });
  }

  render() {
    const { carData, sectionone, sectiontwo, loading, nidUuid } = this.state;
    const handleTextChange = this.handleTextChange;
    const handleSelectChange = this.handleSelectChange;
    const handleBasicDetailsChange = this.handleBasicDetailsChange;
    const onChangeDate = this.onChangeDate;
    const sectionOneSubmit = this.sectionOneSubmit;

    var canSubmit =
      this.state.mileage &&
      this.state.instock &&
      this.state.customerscar &&
      this.state.county &&
      this.state.field_basic_details &&
      this.state.seatColor &&
      this.state.upholstered &&
      this.state.workingKeys &&
      this.state.v5c &&
      this.state.bookpack &&
      this.state.serviceHistory &&
      this.state.bodyworkCondition &&
      this.state.interiorCondition &&
      this.state.warningLights &&
      this.state.alloyWheels &&
      this.state.tyreCondition &&
      this.state.lockingWheel &&
      this.state.vehicleOnFinance &&
      this.state.expectedAmount
        ? true
        : false;
    if (this.state.serviceHistory == 'Full or partial service history') {
      if (
        !this.state.numServices ||
        !this.state.mainDealerServices ||
        !this.state.lastServiceDate ||
        !this.state.mileageAtLastService ||
        !this.state.camBeltChanged
      ) {
        canSubmit = false;
      } else {
        canSubmit = true;
      }
    }
    return (
      <React.Fragment>
        {!carData && (
          <SearchVrm data={this.updateCarData} makeid={this.makeid} />
        )}
        {carData && <TitleData data={carData} />}
        {carData && (
          <div
            ref={section => {
              this.section = section;
            }}
            className="row mt-3 mt-md-5 position-relative"
          >
            {loading && (
              <LoadingOverlay className="d-flex align-items-center justify-content-center">
                <LoaderInner className="text-center">
                  <FontAwesomeIcon
                    className="fa-spin fa-5x"
                    style={blueIcon}
                    icon={faCircleNotch}
                  />
                </LoaderInner>
              </LoadingOverlay>
            )}
            {sectionone && (
              <div className="col-12 col-lg-8 mx-lg-auto">
                <BasicDetails
                  handleTextChange={handleTextChange}
                  handleSelectChange={handleSelectChange}
                  handleBasicDetailsChange={handleBasicDetailsChange}
                />
                <ServiceHistory
                  handleTextChange={handleTextChange}
                  handleSelectChange={handleSelectChange}
                  onChangeDate={onChangeDate}
                />
                <Condition
                  handleTextChange={handleTextChange}
                  handleSelectChange={handleSelectChange}
                />
                <WheelsTryes handleSelectChange={handleSelectChange} />
                <Financial
                  handleTextChange={handleTextChange}
                  handleSelectChange={handleSelectChange}
                />
                <div className="w-100 mt-5">
                  {!canSubmit && (
                    <p className="my-4 text-center text-danger font-weight-bold">
                      Please complete all information to continue
                    </p>
                  )}
                  <Submit disabled={!canSubmit} onClick={sectionOneSubmit}>
                    Add photos
                  </Submit>
                </div>
              </div>
            )}
            {sectiontwo && (
              <div className="col-12 col-lg-8 mx-lg-auto">
                <Images nid={this.state.backendNid} nidUuid={nidUuid} />
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default DealerSubmit;
