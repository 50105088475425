import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { upload_photo, attach_multiple_photos_to_node } from 'helpers/backend';

const thumbsContainer = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const thumb = {};

const thumbInner = {
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  maxHeight: '150px',
};

const dropZone = {
  border: '2px dashed #f2f2f2',
  borderRadius: '10px',
  padding: '15px',
  backgroundColor: '#f2f2f254',
  cursor: 'pointer',
};

const dropZoneCaption = {
  fontSize: '0.8rem',
};

function ImageDropzoneMultiple(props) {
  const nid = props.nid;
  const field = props.field;
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: acceptedFiles => {
      acceptedFiles.map(file => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = function() {
          var result = reader.result;
          var fileName = file.name.replace(/\s+/g, '');
          var saveFileAs = Date.now() + '_' + fileName;
          upload_photo(saveFileAs, result, field, nid).then(res => {
            attach_multiple_photos_to_node(nid, field, res.data.uuid[0].value);
          });
        };
      });

      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img alt="" className="w-100" src={file.preview} style={img} />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <div className="col-6 col-lg-3 mb-4">
      <div {...getRootProps({ className: 'dropzone' })} style={dropZone}>
        <input {...getInputProps()} />
        {files == 0 && (
          <p className="m-0" style={dropZoneCaption}>
            Drag and drop a file here, or click to select
          </p>
        )}
        <div style={thumbsContainer}>{thumbs}</div>
      </div>
    </div>
  );
}

export default ImageDropzoneMultiple;
