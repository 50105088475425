import React, { Component } from 'react';
import { navigate } from 'gatsby';
import ImageDropzone from './images/ImageDropzone';
import ImageDropzoneMultiple from './images/ImageDropzoneMultiple';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import { complete_lead, send_thank_you_email } from 'helpers/backend';

const blueIcon = {
  color: '#2cabde',
};

const Wrapper = styled.div`
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  border-radius: 10px;
`;

const LoaderInner = styled.div`
  padding: 100px;
`;

const Submit = styled.button`
  width: 100%;
  background-color: #2cabde;
  border: none;
  border-radius: 8px;
  color: #fff;
  opacity: 1;
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
  padding: 10px;
  transition-duration: 0.15s;
  transition-property: all;
  transition-timing-function: ease-in-out;
  box-shadow: none   
    &:hover {
    opacity: 0.9;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  }
  :disabled {
    background-color: #a9a9a9;
    &:hover {
      opacity: 1;
      box-shadow: none;
      cursor: not-allowed;
    }
  }
`;

const addBtnStyle = {
  height: '100%',
  width: '100%',
  border: '1px dashed #2cabde',
  borderRadius: '10px',
  color: '#2cabde',
};

class Images extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nid: this.props.nid,
      nidUuid: this.props.nidUuid,
      open: false,
      saving: false,
      complete: false,
      field_front_driver_side: false,
      field_back_driver_side: false,
      field_front_passenger_side: false,
      field_back_passenger_side: false,
      field_front_seats: false,
      field_rear_seats: false,
      field_dashboard: false,
      field_boot_interior: false,
      field_wheel_front_driver_side: false,
      field_wheel_rear_driver_side: false,
      field_wheel_front_passenger_side: false,
      field_wheel_rear_passenger_side: false,
      multiImage: 1,
    };
    this.ImgUploaded = this.ImgUploaded.bind(this);
    this.complete = this.complete.bind(this);
  }

  ImgUploaded(field) {
    this.setState({
      [field]: true,
    });
  }

  complete() {
    this.setState({
      saving: true,
    });
    complete_lead(this.state.nid).then(res => {
      send_thank_you_email(res.data, 'completed').then(res => {
        setTimeout(() => {
          navigate('/account');
        }, 1000);
      });
    });
  }

  addAnother() {
    this.setState(state => {
      return { multiImage: state.multiImage + 1 };
    });
  }

  render() {
    const complete = this.complete;
    const isEnabled =
      this.state.field_front_driver_side && this.state.field_front_seats;
    const { multiImage } = this.state;

    const items = [];
    for (var i = 0; i < multiImage; i++) {
      items.push(
        <ImageDropzoneMultiple
          key={i}
          nid={this.state.nidUuid}
          field="field_scratches_and_damage"
        />
      );
    }
    return (
      <div className="position-relative">
        {this.state.saving && (
          <LoadingOverlay className="d-flex align-items-center justify-content-center">
            <LoaderInner className="text-center">
              <FontAwesomeIcon
                className="fa-spin fa-5x"
                style={blueIcon}
                icon={faCircleNotch}
              />
            </LoaderInner>
          </LoadingOverlay>
        )}
        <div
          ref={images => {
            this.form = images;
          }}
          className="row"
        >
          <div className="col-12">
            <div className="row mb-4">
              <div className="col-12">
                <h2 className="mb-0 font-weight-bold">
                  Upload images (Final Step)
                </h2>
                <p className="mt-2">
                  If you dont have all photos available, we require a minimum of
                  one exterior and one interior image to complete the advert.
                  For best results please upload all images as requested below
                </p>
              </div>
            </div>
            <Wrapper>
              <div className="row">
                <div className="col-12 mb-3">
                  <h3 className="mb-0 font-weight-bold">Exterior images</h3>
                </div>
                <ImageDropzone
                  nid={this.state.nid}
                  field="field_front_driver_side"
                  title="Front"
                  desc="Driver Side"
                  uploaded={this.ImgUploaded}
                />
                <ImageDropzone
                  nid={this.state.nid}
                  field="field_back_driver_side"
                  title="Back"
                  desc="Driver Side"
                  uploaded={this.ImgUploaded}
                />
                <ImageDropzone
                  nid={this.state.nid}
                  field="field_front_passenger_side"
                  title="Front"
                  desc="Passenger Side"
                  uploaded={this.ImgUploaded}
                />
                <ImageDropzone
                  nid={this.state.nid}
                  field="field_back_passenger_side"
                  title="Back"
                  desc="Passenger Side"
                  uploaded={this.ImgUploaded}
                />
              </div>
            </Wrapper>
            <Wrapper className="my-4">
              <div className="row">
                <div className="col-12 mb-3">
                  <h3 className="mb-0 font-weight-bold">Interior images</h3>
                </div>
                <ImageDropzone
                  nid={this.state.nid}
                  field="field_front_seats"
                  title="Front Seats"
                  desc=""
                  uploaded={this.ImgUploaded}
                />
                <ImageDropzone
                  nid={this.state.nid}
                  field="field_rear_seats"
                  title="Rear Seats"
                  desc=""
                  uploaded={this.ImgUploaded}
                />
                <ImageDropzone
                  nid={this.state.nid}
                  field="field_dashboard"
                  title="Dashboard"
                  desc=""
                  uploaded={this.ImgUploaded}
                />
                <ImageDropzone
                  nid={this.state.nid}
                  field="field_boot_interior"
                  title="Boot"
                  desc=""
                  uploaded={this.ImgUploaded}
                />
              </div>
            </Wrapper>
            <Wrapper>
              <div className="row">
                <div className="col-12 mb-3">
                  <h3 className="mb-0 font-weight-bold">Wheels</h3>
                </div>
                <ImageDropzone
                  nid={this.state.nid}
                  field="field_wheel_front_driver_side"
                  title="Front"
                  desc="Driver side"
                  uploaded={this.ImgUploaded}
                />
                <ImageDropzone
                  nid={this.state.nid}
                  field="field_wheel_rear_driver_side"
                  title="Rear"
                  desc="Driver side"
                  uploaded={this.ImgUploaded}
                />
                <ImageDropzone
                  nid={this.state.nid}
                  field="field_wheel_front_passenger_side"
                  title="Front"
                  desc="Passenger Side"
                  uploaded={this.ImgUploaded}
                />
                <ImageDropzone
                  nid={this.state.nid}
                  field="field_wheel_rear_passenger_side"
                  title="Rear"
                  desc="Passenger Side"
                  uploaded={this.ImgUploaded}
                />
              </div>
            </Wrapper>
            <Wrapper className="mt-4">
              <div className="row">
                <div className="col-12 mb-3">
                  <h3 className="mb-0 font-weight-bold">Scratches & Damage</h3>
                  <p>Please add images of any damage</p>
                </div>
                {items}
                <div className="col-6 col-lg-3 mb-4">
                  <button
                    disabled={multiImage >= 10}
                    style={addBtnStyle}
                    onClick={this.addAnother.bind(this)}
                  >
                    Add another
                  </button>
                </div>
              </div>
            </Wrapper>
          </div>
          <div className="col-12 mt-5">
            <Submit disabled={!isEnabled} onClick={complete}>
              Save & finish
            </Submit>
          </div>
        </div>
      </div>
    );
  }
}

export default Images;
