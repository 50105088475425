import React, { Component } from 'react';
import axios from 'axios';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { get_backend_select_options } from 'helpers/backend';

const formGroupWrapper = {
  backgroundColor: '#fff',
  padding: '30px',
  borderRadius: '10px',
};

class Financial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleOnFinanceOptions: [],
      vehicleOnFinance: '',
    };
    this.buildSelectOptions = this.buildSelectOptions.bind(this);
    axios
      .all([get_backend_select_options('field_is_the_vehicle_on_finance')])
      .then(
        axios.spread(vehicleOnFinanceOptions => {
          this.buildSelectOptions(
            vehicleOnFinanceOptions.data.settings.allowed_values,
            'vehicleOnFinanceOptions'
          );
        })
      );
  }

  buildSelectOptions(options, stateValue) {
    {
      Object.keys(options).map(key => {
        this.setState({
          [stateValue]: {
            ...this.state[stateValue],
            [key]: options[key],
          },
        });
      });
    }
  }

  handleSelectChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  render() {
    return (
      <div className="mt-4" style={formGroupWrapper}>
        <h3>Financial</h3>
        <div className="mt-4">
          <p className="mb-1">Does the car have any outstanding finance?</p>
          <FormControl variant="filled">
            <InputLabel htmlFor="vehicleOnFinance">Select</InputLabel>
            <Select
              value={this.state.vehicleOnFinance}
              onChange={e => {
                this.handleSelectChange(e);
                this.props.handleSelectChange(e);
              }}
              inputProps={{
                name: 'vehicleOnFinance',
              }}
            >
              {Object.keys(this.state.vehicleOnFinanceOptions).map(key => {
                return (
                  <MenuItem key={key} value={key}>
                    {this.state.vehicleOnFinanceOptions[key]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="mt-4">
          <p className="mb-1">What target price are we trying to achieve?</p>
          <TextField
            type="number"
            label="Enter target price"
            name="expectedAmount"
            onChange={this.props.handleTextChange}
            variant="filled"
          />
        </div>
      </div>
    );
  }
}

export default Financial;
