import React, { Component } from 'react';
import axios from 'axios';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { get_backend_select_options } from 'helpers/backend';

const formGroupWrapper = {
  backgroundColor: '#fff',
  padding: '30px',
  borderRadius: '10px',
};

class WheelsTryes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alloyWheelsOptions: [],
      tyreConditionOptions: [],
      lockingWheelOptions: [],
      tyreCondition: '',
    };
    this.buildSelectOptions = this.buildSelectOptions.bind(this);
    axios
      .all([
        get_backend_select_options('field_alloy_wheel_condition'),
        get_backend_select_options('field_tyre_condition'),
        get_backend_select_options('field_do_you_have_the_locking_wh'),
      ])
      .then(
        axios.spread(
          (alloyWheelsOptions, tyreConditionOptions, lockingWheelOptions) => {
            this.buildSelectOptions(
              alloyWheelsOptions.data.settings.allowed_values,
              'alloyWheelsOptions'
            );
            this.buildSelectOptions(
              tyreConditionOptions.data.settings.allowed_values,
              'tyreConditionOptions'
            );
            this.buildSelectOptions(
              lockingWheelOptions.data.settings.allowed_values,
              'lockingWheelOptions'
            );
          }
        )
      );
  }

  buildSelectOptions(options, stateValue) {
    {
      Object.keys(options).map(key => {
        this.setState({
          [stateValue]: {
            ...this.state[stateValue],
            [key]: options[key],
          },
        });
      });
    }
  }

  handleSelectChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  render() {
    return (
      <div className="mt-4" style={formGroupWrapper}>
        <h3>Wheels & tryes</h3>
        <div className="mt-4">
          <p className="mb-1">Alloy wheel condition?</p>
          <FormControl component="fieldset">
            <RadioGroup
              name="alloyWheels"
              onChange={e => {
                this.handleSelectChange(e);
                this.props.handleSelectChange(e);
              }}
            >
              {Object.keys(this.state.alloyWheelsOptions).map(key => {
                return (
                  <FormControlLabel
                    key={key}
                    value={key}
                    control={<Radio />}
                    label={key}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </div>
        <div className="mt-4">
          <p className="mb-1">Tyre condition?</p>
          <FormControl variant="filled">
            <InputLabel htmlFor="tyreCondition">Select condition</InputLabel>
            <Select
              value={this.state.tyreCondition}
              onChange={e => {
                this.handleSelectChange(e);
                this.props.handleSelectChange(e);
              }}
              inputProps={{
                name: 'tyreCondition',
              }}
            >
              {Object.keys(this.state.tyreConditionOptions).map(key => {
                return (
                  <MenuItem key={key} value={key}>
                    {this.state.tyreConditionOptions[key]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="mt-4">
          <p className="mb-1">Do you have the locking wheel nut?</p>
          <FormControl component="fieldset">
            <RadioGroup
              name="lockingWheel"
              onChange={e => {
                this.handleSelectChange(e);
                this.props.handleSelectChange(e);
              }}
            >
              {Object.keys(this.state.lockingWheelOptions).map(key => {
                return (
                  <FormControlLabel
                    key={key}
                    value={key}
                    control={<Radio />}
                    label={key}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    );
  }
}

export default WheelsTryes;
