import React, { Component } from 'react';
import styled from 'styled-components';

const PlateField = styled.input`
  font-family: 'UKNumberPlate';
  -webkit-appearance: none;
  width: 100%;
  background-color: #f7b733;
  border-style: none;
  border-color: #f7b733;
  box-shadow: none;
  font-size: 40px;
  line-height: 40px;
  height: 60px;
  padding-bottom: 0;
  padding-top: 0;
  margin-bottom: 20px;
  text-align: center;
  padding: 0 15px;
  border-radius: 8px;
  transition-duration: 0.15s;
  transition-property: box-shadow;
  transition-timing-function: ease-in-out;
  &:focus {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  }
`;

const LogoImg = styled.img`
  width: 100%;
  @media (max-width: 767.98px) {
    width: 50% !important;
  }
`;

const ListStyle = {
  listStyle: 'none',
  fontWeight: 'bold',
  marginBottom: '0.2rem',
};

const ListStyleMake = {
  listStyle: 'none',
  fontSize: '1.4rem',
  fontWeight: 'bold',
  marginBottom: '0.2rem',
};

class TitleData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carData: this.props.data,
    };
  }
  render() {
    const { carData } = this.state;
    return (
      <div className="row">
        <div className="col-12 col-lg-8 mx-lg-auto">
          <div className="row">
            <div className="col-12 col-md-4">
              <LogoImg
                alt={carData.DataItems.VehicleRegistration.Make}
                className="d-block mx-auto mx-md-0 mb-4 mb-md-0"
                src={`https://cdn.vdicheck.com/badges/${
                  carData.DataItems.VehicleRegistration.Make
                }.png`}
              />
            </div>
            <div className="col-12 col-md-8">
              <PlateField
                type="text"
                name="plate"
                value={carData.DataItems.VehicleRegistration.Vrm}
                disabled={true}
              />
              <ul className="text-left p-0">
                <li style={ListStyleMake}>
                  {carData.DataItems.VehicleRegistration.MakeModel}
                </li>
              </ul>
              <ul className="text-left p-0 d-flex">
                <li style={ListStyle}>
                  {carData.DataItems.VehicleRegistration.YearOfManufacture}
                </li>
                <li style={ListStyle} className="mx-3">
                  {carData.DataItems.VehicleRegistration.Colour}
                </li>
                <li style={ListStyle}>
                  {carData.DataItems.VehicleRegistration.FuelType}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TitleData;
