import React, { Component } from 'react';
import axios from 'axios';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { get_backend_select_options } from 'helpers/backend';

const formGroupWrapper = {
  backgroundColor: '#fff',
  padding: '30px',
  borderRadius: '10px',
};

class Condition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bodyworkConditionOptions: [],
      interiorConditionOptions: [],
      warningLightsOptions: [],
      bodyworkCondition: '',
      interiorCondition: '',
    };
    this.buildSelectOptions = this.buildSelectOptions.bind(this);
    axios
      .all([
        get_backend_select_options('field_bodywork_condition'),
        get_backend_select_options('field_interior_condition'),
        get_backend_select_options('field_warning_lights_on_dashboar'),
      ])
      .then(
        axios.spread(
          (
            bodyworkConditionOptions,
            interiorConditionOptions,
            warningLightsOptions
          ) => {
            this.buildSelectOptions(
              bodyworkConditionOptions.data.settings.allowed_values,
              'bodyworkConditionOptions'
            );
            this.buildSelectOptions(
              interiorConditionOptions.data.settings.allowed_values,
              'interiorConditionOptions'
            );
            this.buildSelectOptions(
              warningLightsOptions.data.settings.allowed_values,
              'warningLightsOptions'
            );
          }
        )
      );
  }

  buildSelectOptions(options, stateValue) {
    {
      Object.keys(options).map(key => {
        this.setState({
          [stateValue]: {
            ...this.state[stateValue],
            [key]: options[key],
          },
        });
      });
    }
  }

  handleSelectChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  render() {
    return (
      <div className="mt-4" style={formGroupWrapper}>
        <h3>Condition</h3>
        <div className="mt-4">
          <p className="mb-1">Bodywork condition?</p>
          <FormControl variant="filled">
            <InputLabel htmlFor="bodyworkCondition">
              Select condition
            </InputLabel>
            <Select
              value={this.state.bodyworkCondition}
              onChange={e => {
                this.handleSelectChange(e);
                this.props.handleSelectChange(e);
              }}
              inputProps={{
                name: 'bodyworkCondition',
              }}
            >
              {Object.keys(this.state.bodyworkConditionOptions).map(key => {
                return (
                  <MenuItem key={key} value={key}>
                    {this.state.bodyworkConditionOptions[key]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="mt-4">
          <p className="mb-1">Interior condition?</p>
          <FormControl variant="filled">
            <InputLabel htmlFor="interiorCondition">
              Select condition
            </InputLabel>
            <Select
              value={this.state.interiorCondition}
              onChange={e => {
                this.handleSelectChange(e);
                this.props.handleSelectChange(e);
              }}
              inputProps={{
                name: 'interiorCondition',
              }}
            >
              {Object.keys(this.state.interiorConditionOptions).map(key => {
                return (
                  <MenuItem key={key} value={key}>
                    {this.state.interiorConditionOptions[key]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="mt-4">
          <p className="mb-1">Warning lights on dashboard?</p>
          <FormControl component="fieldset">
            <RadioGroup
              name="warningLights"
              onChange={e => {
                this.handleSelectChange(e);
                this.props.handleSelectChange(e);
              }}
            >
              {Object.keys(this.state.warningLightsOptions).map(key => {
                return (
                  <FormControlLabel
                    key={key}
                    value={key}
                    control={<Radio />}
                    label={key}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </div>
        <div className="mt-4">
          <p className="mb-1">Details on any interior or exterior damage</p>
          <TextField
            label="Damage notes"
            name="detailsIntOrExt"
            onChange={this.props.handleTextChange}
            variant="filled"
          />
        </div>
      </div>
    );
  }
}

export default Condition;
