import React, { Component } from 'react';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import {
  get_backend_checkbox_options,
  get_backend_select_options,
  get_backend_all_county_options,
} from 'helpers/backend';

const formGroupWrapper = {
  backgroundColor: '#fff',
  padding: '30px',
  borderRadius: '10px',
};

class BasicDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countyOptions: [],
      basicdetails: [],
      seatColorOptions: [],
      upholsteredOptions: [],
      workingKeysOptions: [],
      v5cLogbookOptions: [],
      bookpackOptions: [],
      instock: '',
      customerscar: '',
      county: '',
      seatColor: '',
      upholstered: '',
      workingKeys: '',
    };
    this.selected = {};
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.buildCheckboxes = this.buildCheckboxes.bind(this);
    this.buildSelectOptions = this.buildSelectOptions.bind(this);

    axios
      .all([
        get_backend_checkbox_options('field_basic_details'),
        get_backend_select_options('field_what_colour_are_the_seats'),
        get_backend_select_options('field_how_are_they_upholstered'),
        get_backend_select_options('field_how_many_working_keys'),
        get_backend_select_options('field_do_you_have_the_v5c_logboo'),
        get_backend_select_options('field_do_you_have_the_book_pack_'),
        get_backend_all_county_options(),
      ])
      .then(
        axios.spread(
          (
            basicDetails,
            seatColorOptions,
            upholsteredOptions,
            workingKeysOptions,
            v5cLogbookOptions,
            bookpackOptions,
            countyOptions
          ) => {
            this.buildCheckboxes(
              basicDetails.data.settings.allowed_values,
              'basicdetails'
            );
            this.buildSelectOptions(
              seatColorOptions.data.settings.allowed_values,
              'seatColorOptions'
            );
            this.buildSelectOptions(
              upholsteredOptions.data.settings.allowed_values,
              'upholsteredOptions'
            );
            this.buildSelectOptions(
              workingKeysOptions.data.settings.allowed_values,
              'workingKeysOptions'
            );
            this.buildSelectOptions(
              v5cLogbookOptions.data.settings.allowed_values,
              'v5cLogbookOptions'
            );
            this.buildSelectOptions(
              bookpackOptions.data.settings.allowed_values,
              'bookpackOptions'
            );
            this.buildCountySelectOptions(countyOptions.data, 'countyOptions');
          }
        )
      );
  }

  buildCheckboxes(options, stateValue) {
    {
      Object.keys(options).map(key => {
        this.setState({
          [stateValue]: {
            ...this.state[stateValue],
            [key]: false,
          },
        });
      });
    }
  }

  buildSelectOptions(options, stateValue) {
    {
      Object.keys(options).map(key => {
        this.setState({
          [stateValue]: {
            ...this.state[stateValue],
            [key]: options[key],
          },
        });
      });
    }
  }

  buildCountySelectOptions(options, stateValue) {
    {
      Object.keys(options).map(key => {
        this.setState({
          [stateValue]: {
            ...this.state[stateValue],
            [options[key].tid]: options[key].name,
          },
        });
      });
    }
  }

  handleSelectChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleBasicDetailsChange(e) {
    this.setState({
      basicdetails: {
        ...this.state.basicdetails,
        [e.target.name]: !this.state.basicdetails[e.target.name],
      },
    });
    if (e.target.checked) {
      this.selected[e.target.name] = true;
    } else {
      delete this.selected[e.target.name];
    }
  }

  render() {
    return (
      <div style={formGroupWrapper}>
        <h3>Basic Details</h3>
        <div className="mt-4">
          <p className="mb-1">What is the cars mileage</p>
          <TextField
            type="number"
            label="Enter mileage"
            name="mileage"
            onChange={this.props.handleTextChange}
            variant="filled"
          />
        </div>
        <div className="mt-4">
          <p className="mb-1">Is this vehicle currently in stock?</p>
          <FormControl variant="filled">
            <InputLabel htmlFor="instock">Select</InputLabel>
            <Select
              value={this.state.instock}
              onChange={e => {
                this.handleSelectChange(e);
                this.props.handleSelectChange(e);
              }}
              inputProps={{
                name: 'instock',
              }}
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="mt-4">
          <p className="mb-1">Is this vehicle a customers car?</p>
          <FormControl variant="filled">
            <InputLabel htmlFor="customerscar">Select</InputLabel>
            <Select
              value={this.state.customerscar}
              onChange={e => {
                this.handleSelectChange(e);
                this.props.handleSelectChange(e);
              }}
              inputProps={{
                name: 'customerscar',
              }}
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="mt-4">
          <p className="mb-1">Where is the car located?</p>
          <FormControl variant="filled">
            <InputLabel htmlFor="county">Select County</InputLabel>
            <Select
              value={this.state.county}
              onChange={e => {
                this.handleSelectChange(e);
                this.props.handleSelectChange(e);
              }}
              inputProps={{
                name: 'county',
              }}
            >
              {Object.keys(this.state.countyOptions).map(key => {
                return (
                  <MenuItem key={key} value={key}>
                    {this.state.countyOptions[key]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="mt-4">
          <p className="mb-1">Does the car have any of these features?</p>
          <FormControl component="fieldset">
            <FormGroup>
              {Object.keys(this.state.basicdetails).map(key => {
                return (
                  <FormControlLabel
                    key={key}
                    control={
                      <Checkbox
                        name={key}
                        checked={this.state.basicdetails[key]}
                        onChange={e => {
                          this.handleBasicDetailsChange(e);
                          this.props.handleBasicDetailsChange(this.selected);
                        }}
                        value={key}
                      />
                    }
                    label={key.replace(/_/g, ' ')}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        </div>
        <div className="mt-4">
          <p className="mb-1">What colour are the seats?</p>
          <FormControl variant="filled">
            <InputLabel htmlFor="seatColor">Select color</InputLabel>
            <Select
              value={this.state.seatColor}
              onChange={e => {
                this.handleSelectChange(e);
                this.props.handleSelectChange(e);
              }}
              inputProps={{
                name: 'seatColor',
              }}
            >
              {Object.keys(this.state.seatColorOptions).map(key => {
                return (
                  <MenuItem key={key} value={key}>
                    {this.state.seatColorOptions[key]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="mt-4">
          <p className="mb-1">How are they upholstered?</p>
          <FormControl variant="filled">
            <InputLabel htmlFor="upholstered">Select material</InputLabel>
            <Select
              value={this.state.upholstered}
              onChange={e => {
                this.handleSelectChange(e);
                this.props.handleSelectChange(e);
              }}
              inputProps={{
                name: 'upholstered',
              }}
            >
              {Object.keys(this.state.upholsteredOptions).map(key => {
                return (
                  <MenuItem key={key} value={key}>
                    {this.state.upholsteredOptions[key]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="mt-4">
          <p className="mb-1">How many working keys do you have for the car?</p>
          <FormControl variant="filled">
            <InputLabel htmlFor="workingKeys">Select keys</InputLabel>
            <Select
              value={this.state.workingKeys}
              onChange={e => {
                this.handleSelectChange(e);
                this.props.handleSelectChange(e);
              }}
              inputProps={{
                name: 'workingKeys',
              }}
            >
              {Object.keys(this.state.workingKeysOptions).map(key => {
                return (
                  <MenuItem key={key} value={key}>
                    {this.state.workingKeysOptions[key]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="mt-4">
          <p className="mb-1">Do you have the V5C logbook?</p>
          <FormControl component="fieldset">
            <RadioGroup
              name="v5c"
              onChange={e => {
                this.handleSelectChange(e);
                this.props.handleSelectChange(e);
              }}
            >
              {Object.keys(this.state.v5cLogbookOptions).map(key => {
                return (
                  <FormControlLabel
                    key={key}
                    value={key}
                    control={<Radio />}
                    label={key}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </div>
        <div className="mt-4">
          <p className="mb-1">Do you have the book pack?</p>
          <FormControl component="fieldset">
            <RadioGroup
              name="bookpack"
              onChange={e => {
                this.handleSelectChange(e);
                this.props.handleSelectChange(e);
              }}
            >
              {Object.keys(this.state.bookpackOptions).map(key => {
                return (
                  <FormControlLabel
                    key={key}
                    value={key}
                    control={<Radio />}
                    label={key}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </div>
        <div className="mt-4">
          <TextField
            name="description"
            id="outlined-multiline-flexible"
            label="Optional further details about the car e.g Modifications"
            multiline
            rowsMax="4"
            rows="4"
            onChange={this.props.handleTextChange}
            margin="normal"
            variant="filled"
          />
        </div>
      </div>
    );
  }
}

export default BasicDetails;
