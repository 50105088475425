/* eslint-disable jsx-a11y/no-autofocus */
import React, { Component } from 'react';
import axios from 'axios';
import Countdown from 'react-countdown-now';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const blueIcon = {
  color: '#2cabde',
};

const inputDisabled = {
  width: '100%',
  padding: '15px',
  borderRadius: '5px',
  backgroundColor: '#efefef',
  border: '0px',
};

const codeInput = {
  width: '100%',
  padding: '10px 5px',
  backgroundColor: '#efefef',
  textAlign: 'center',
  fontSize: '2.5rem',
  border: '0px',
};

const LoadingOverlay = styled.div`
  position: absolute;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  border-radius: 10px;
`;

const LoaderInner = styled.div`
  padding: 100px;
`;

const Btn = styled.button`
  -webkit-appearance: none;
  width: 100%;
  background-color: #2cabde;
  border: none;
  border-radius: 8px;
  color: #fff;
  opacity: 1;
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
  padding: 10px;
  -webkit-transition-duration: 0.15s;
  transition-duration: 0.15s;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  box-shadow: none;
  &:hover {
    opacity: 0.9;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  }
  &:disabled {
    background-color: #efefef;
    cursor: not-allowed;
  }
`;

// Renderer callback with condition
const renderer = ({ seconds }) => {
  return <span className="font-weight-bold">Code expires in: {seconds}s</span>;
};

const codeFields = [
  {
    type: 'number',
    value: 'codefirst',
  },
  {
    type: 'number',
    value: 'codesecond',
  },
  {
    type: 'number',
    value: 'codethird',
  },
  {
    type: 'number',
    value: 'codefourth',
  },
  {
    type: 'number',
    value: 'codefifth',
  },
  {
    type: 'number',
    value: 'codesixth',
  },
];

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
      email: this.props.email,
      counterDate: null,
      resetTimer: false,
      loading: false,
      code: null,
      codefirst: '',
      codesecond: '',
      codethird: '',
      codefourth: '',
      codefifth: '',
      codesixth: '',
      canUpdatePassword: false,
      showPassword: false,
      password: '',
      passwordResetComplete: false,
      drawn: false,
    };
    this.codefirst = React.createRef();
    this.codesecond = React.createRef();
    this.codethird = React.createRef();
    this.codefourth = React.createRef();
    this.codefifth = React.createRef();
    this.codesixth = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.open != this.props.open) {
      this.setState({
        open: this.props.open,
        resetTimer: false,
        code: null,
        codefirst: '',
        codesecond: '',
        codethird: '',
        codefourth: '',
        codefifth: '',
        codesixth: '',
        canUpdatePassword: false,
        password: '',
        showPassword: false,
        passwordResetComplete: false,
        drawn: false,
      });
    }
    if (prevProps.email != this.props.email) {
      this.setState({
        email: this.props.email,
        resetTimer: false,
        code: null,
        codefirst: '',
        codesecond: '',
        codethird: '',
        codefourth: '',
        codefifth: '',
        codesixth: '',
        canUpdatePassword: false,
        password: '',
        showPassword: false,
        passwordResetComplete: false,
        drawn: false,
      });
    }
    return null;
  }

  sendReset() {
    this.setState({
      loading: true,
    });
    const oneTimeCode = Math.floor(100000 + Math.random() * 900000);
    // send email to user with oneTimeCode
    const data = {
      email: this.state.email,
      code: oneTimeCode,
    };
    let uri = `${process.env.FE_RESOURCE_URL}/sendresetpassword`;
    axios
      .post(uri, data)
      .then(res => {
        this.setState({
          counterDate: Date.now() + 59000,
          resetTimer: true,
          code: oneTimeCode,
          canUpdatePassword: false,
          loading: false,
        });
      })
      .catch(error => {
        this.setState({
          loading: false,
        });
      });
  }

  notIntime() {
    this.setState({
      counterDate: null,
      resetTimer: false,
      code: null,
      codefirst: '',
      codesecond: '',
      codethird: '',
      codefourth: '',
      codefifth: '',
      codesixth: '',
      canUpdatePassword: false,
      password: '',
      showPassword: false,
      passwordResetComplete: false,
      drawn: false,
    });
  }

  onCodeChangeFirst(e) {
    if (e.target.value.length <= e.target.maxLength) {
      this.setState(
        {
          [e.target.name]: e.target.value,
        },
        () => {
          if (this.state.codefirst) {
            this.codesecond.current.focus();
          }
        }
      );
    }
  }
  onCodeChangeSecond(e) {
    if (e.target.value.length <= e.target.maxLength) {
      this.setState(
        {
          [e.target.name]: e.target.value,
        },
        () => {
          if (this.state.codesecond) {
            this.codethird.current.focus();
          } else if (this.state.codesecond.length == 0) {
            this.setState(
              {
                codefirst: '',
              },
              () => {
                this.codefirst.current.focus();
              }
            );
          }
        }
      );
    }
  }
  onCodeChangeThird(e) {
    if (e.target.value.length <= e.target.maxLength) {
      this.setState(
        {
          [e.target.name]: e.target.value,
        },
        () => {
          if (this.state.codethird) {
            this.codefourth.current.focus();
          } else if (this.state.codethird.length == 0) {
            this.setState(
              {
                codesecond: '',
              },
              () => {
                this.codesecond.current.focus();
              }
            );
          }
        }
      );
    }
  }
  onCodeChangeFourth(e) {
    if (e.target.value.length <= e.target.maxLength) {
      this.setState(
        {
          [e.target.name]: e.target.value,
        },
        () => {
          if (this.state.codefourth) {
            this.codefifth.current.focus();
          } else if (this.state.codefourth.length == 0) {
            this.setState(
              {
                codethird: '',
              },
              () => {
                this.codethird.current.focus();
              }
            );
          }
        }
      );
    }
  }
  onCodeChangeFifth(e) {
    if (e.target.value.length <= e.target.maxLength) {
      this.setState(
        {
          [e.target.name]: e.target.value,
        },
        () => {
          if (this.state.codefifth) {
            this.codesixth.current.focus();
          } else if (this.state.codefifth.length == 0) {
            this.setState(
              {
                codefourth: '',
              },
              () => {
                this.codefourth.current.focus();
              }
            );
          }
        }
      );
    }
  }
  onCodeChangeSixth(e) {
    if (e.target.value.length <= e.target.maxLength) {
      this.setState(
        {
          [e.target.name]: e.target.value,
        },
        () => {
          if (this.state.codesixth) {
            this.setState({
              loading: true,
            });
            setTimeout(() => {
              if (
                this.state.codefirst &&
                this.state.codesecond &&
                this.state.codethird &&
                this.state.codefourth &&
                this.state.codefifth &&
                this.state.codesixth
              ) {
                const submittedCode =
                  this.state.codefirst +
                  this.state.codesecond +
                  this.state.codethird +
                  this.state.codefourth +
                  this.state.codefifth +
                  this.state.codesixth;
                if (this.state.code == submittedCode) {
                  this.setState({
                    canUpdatePassword: true,
                    loading: false,
                    resetTimer: false,
                    code: null,
                    codefirst: '',
                    codesecond: '',
                    codethird: '',
                    codefourth: '',
                    codefifth: '',
                    codesixth: '',
                  });
                } else {
                  this.setState({
                    loading: false,
                  });
                }
              } else {
                this.setState({
                  loading: false,
                });
              }
            }, 2000);
          } else if (this.state.codesixth.length == 0) {
            this.setState(
              {
                codefifth: '',
              },
              () => {
                this.codefifth.current.focus();
              }
            );
          }
        }
      );
    }
  }

  handleChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleClickShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  handleMouseDownPassword(e) {
    e.preventDefault();
  }

  doPasswordUpdate(e) {
    this.setState({
      loading: true,
    });
    var basicAuthCredential = `${process.env.BACKEND_USERNAME}:${
      process.env.BACKEND_PASSWORD
    }`;
    var bace64 = btoa(basicAuthCredential);
    axios
      .get(
        `${process.env.BACKEND_URL}/rest-api/get_uid_email?mail=${
          this.state.email
        }&_format=hal_json`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/hal+json',
            'X-CSRF-Token': `${process.env.BACKEND_CSRF_TOKEN}`,
            Authorization: 'Basic ' + bace64,
          },
        }
      )
      .then(res => {
        const uid = res.data[0].uid;
        // now set new password
        axios
          .post(
            `${
              process.env.BACKEND_URL
            }/demo_rest_api/demo_resource?_format=hal_json`,
            JSON.stringify({
              uid: {
                value: uid,
              },
              pass: {
                value: this.state.password,
              },
            }),
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/hal+json',
                'X-CSRF-Token': `${process.env.BACKEND_CSRF_TOKEN}`,
                Authorization: 'Basic ' + bace64,
              },
            }
          )
          .then(res => {
            setTimeout(() => {
              this.setState({
                loading: false,
                passwordResetComplete: true,
              });
              setTimeout(() => {
                this.setState({
                  drawn: true,
                });
                setTimeout(() => {
                  this.props.handleChange();
                }, 3000);
              }, 100);
            }, 2000);
          })
          .catch(function(error) {
            console.log(error);
          });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  render() {
    return (
      <Dialog
        open={this.state.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.props.handleChange}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" className="text-center">
          {'Reset your password'}
        </DialogTitle>
        <DialogContent>
          {!this.state.resetTimer && !this.state.canUpdatePassword && (
            <div className="position-relative">
              {this.state.loading && (
                <LoadingOverlay className="d-flex align-items-center justify-content-center">
                  <LoaderInner className="text-center">
                    <FontAwesomeIcon
                      className="fa-spin fa-5x"
                      style={blueIcon}
                      icon={faCircleNotch}
                    />
                  </LoaderInner>
                </LoadingOverlay>
              )}
              <DialogContentText id="alert-dialog-slide-description">
                To reset your password click the link below and we will email
                you a one-time 6 digit code
              </DialogContentText>
              <input
                style={inputDisabled}
                className="my-3"
                type="text"
                value={this.state.email}
                disabled
              />
              <Btn onClick={this.sendReset.bind(this)}>Send one-time code</Btn>
            </div>
          )}
          {this.state.resetTimer && (
            <div className="position-relative">
              {this.state.loading && (
                <LoadingOverlay className="d-flex align-items-center justify-content-center">
                  <LoaderInner className="text-center">
                    <FontAwesomeIcon
                      className="fa-spin fa-5x"
                      style={blueIcon}
                      icon={faCircleNotch}
                    />
                  </LoaderInner>
                </LoadingOverlay>
              )}
              <DialogContentText id="alert-dialog-slide-description">
                Please enter the 6 digital code we have emailed you
              </DialogContentText>
              <Countdown
                date={this.state.counterDate}
                renderer={renderer}
                onComplete={this.notIntime.bind(this)}
              />
              <div className="row no-gutters pt-4">
                <div className="col">
                  <div className="pr-1">
                    <input
                      style={codeInput}
                      ref={this.codefirst}
                      autoFocus={true}
                      type="number"
                      name="codefirst"
                      value={this.state.codefirst}
                      onChange={this.onCodeChangeFirst.bind(this)}
                      maxLength="1"
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="pr-1">
                    <input
                      style={codeInput}
                      ref={this.codesecond}
                      type="number"
                      name="codesecond"
                      value={this.state.codesecond}
                      onChange={this.onCodeChangeSecond.bind(this)}
                      maxLength="1"
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="pr-1">
                    <input
                      style={codeInput}
                      ref={this.codethird}
                      type="number"
                      name="codethird"
                      value={this.state.codethird}
                      onChange={this.onCodeChangeThird.bind(this)}
                      maxLength="1"
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="pr-1">
                    <input
                      style={codeInput}
                      ref={this.codefourth}
                      type="number"
                      name="codefourth"
                      value={this.state.codefourth}
                      onChange={this.onCodeChangeFourth.bind(this)}
                      maxLength="1"
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="pr-1">
                    <input
                      style={codeInput}
                      ref={this.codefifth}
                      type="number"
                      name="codefifth"
                      value={this.state.codefifth}
                      onChange={this.onCodeChangeFifth.bind(this)}
                      maxLength="1"
                    />
                  </div>
                </div>
                <div className="col">
                  <div>
                    <input
                      style={codeInput}
                      ref={this.codesixth}
                      type="number"
                      name="codesixth"
                      value={this.state.codesixth}
                      onChange={this.onCodeChangeSixth.bind(this)}
                      maxLength="1"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.canUpdatePassword && (
            <div className="position-relative">
              {this.state.loading && (
                <LoadingOverlay className="d-flex align-items-center justify-content-center">
                  <LoaderInner className="text-center">
                    <FontAwesomeIcon
                      className="fa-spin fa-5x"
                      style={blueIcon}
                      icon={faCircleNotch}
                    />
                  </LoaderInner>
                </LoadingOverlay>
              )}

              {!this.state.passwordResetComplete && (
                <React.Fragment>
                  <DialogContentText id="alert-dialog-slide-description">
                    Please update your password
                  </DialogContentText>
                  <div>
                    <TextField
                      variant="filled"
                      type={this.state.showPassword ? 'text' : 'password'}
                      label="Password"
                      value={this.state.password}
                      onChange={this.handleChangePassword.bind(this)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword.bind(this)}
                              onMouseDown={this.handleMouseDownPassword.bind(
                                this
                              )}
                            >
                              {this.state.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Btn
                      className="mt-4"
                      disabled={!this.state.password}
                      onClick={this.doPasswordUpdate.bind(this)}
                    >
                      Update password
                    </Btn>
                  </div>
                </React.Fragment>
              )}

              {this.state.passwordResetComplete && (
                <React.Fragment>
                  <div
                    className={`trigger ${this.state.drawn ? 'drawn' : ''}`}
                  />
                  <svg viewBox="0 0 37 37">
                    <path
                      className="circ path"
                      d="M30.5 6.5h0c6.6 6.6 6.6 17.4 0 24h0c-6.6 6.6-17.4 6.6-24 0h0c-6.6-6.6-6.6-17.4 0-24h0c6.6-6.7 17.4-6.7 24 0z"
                      fill="none"
                      stroke="#28a745"
                      strokeWidth={3}
                      strokeLinejoin="round"
                      strokeMiterlimit={10}
                    />
                    <path
                      className="tick path"
                      fill="none"
                      stroke="#28a745"
                      strokeWidth={3}
                      strokeLinejoin="round"
                      strokeMiterlimit={10}
                      d="M11.6 20l4.3 4.2 10.5-10.4"
                    />
                  </svg>
                </React.Fragment>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleChange} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ResetPassword;
