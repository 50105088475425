import axios from 'axios';

export function register_user(data) {
  var basicAuthCredential = `${process.env.BACKEND_USERNAME}:${process.env.BACKEND_PASSWORD}`;
  var bace64 = btoa(basicAuthCredential);
  const username =
    data.first_name +
    '_' +
    data.last_name +
    '_' +
    Math.floor(Math.random() * 100000);
  // CREATE USER
  return axios.post(
    `${process.env.BACKEND_URL}/entity/user?_format=hal_json`,
    JSON.stringify({
      _links: {
        type: {
          href: `${process.env.BACKEND_URL_NON_HTTPS}/rest/type/user/user`,
        },
      },
      name: {
        value: username,
      },
      field_first_name: {
        value: data.first_name,
      },
      field_last_name: {
        value: data.last_name,
      },
      field_telephone: {
        value: data.telephone,
      },
      mail: {
        value: data.email,
      },
      field_dealership_name: {
        value: data.dealership_name,
      },
      field_dealership_website_url: {
        value: data.dealership_website,
      },
      field_dealership_postcode: {
        value: data.dealership_postcode,
      },
      field_purchases_all_brands: {
        value: data.makeoptions == 'all' ? 'yes' : 'no',
      },
      field_purchases_from_year: {
        value: data.ageRange[0],
      },
      field_purchases_up_to_year: {
        value: data.ageRange[1],
      },
      field_up_to_what_value_do_you_ty: {
        value: data.carValue,
      },
      ...(data.makes.length > 0
        ? {
            field_dealer_brands: [
              ...data.makes.map(make => {
                return {
                  target_id: make,
                };
              }),
            ],
          }
        : {}),
      roles: [
        {
          target_id: 'dealer',
        },
      ],
      pass: {
        value: data.password,
      },
      field_agreed_to_emails: {
        value: 1,
      },
      status: {
        value: '1',
      },
    }),
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/hal+json',
        'X-CSRF-Token': `${process.env.BACKEND_CSRF_TOKEN}`,
        Authorization: 'Basic ' + bace64,
      },
    }
  );
}

export function login_user(username, password) {
  return axios.post(
    `${process.env.BACKEND_URL}/user/login?_format=json`,
    JSON.stringify({
      name: username,
      pass: password,
    }),
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  );
}

export function number_plate_exists(vrm) {
  var basicAuthCredential = `${process.env.BACKEND_USERNAME}:${process.env.BACKEND_PASSWORD}`;
  var bace64 = btoa(basicAuthCredential);
  return axios.get(
    `${process.env.BACKEND_URL}/lead/exists?vrm=${vrm}&_format=hal_json`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/hal+json',
        'X-CSRF-Token': `${process.env.BACKEND_CSRF_TOKEN}`,
        Authorization: 'Basic ' + bace64,
      },
    }
  );
}

export function roles_from_email(email) {
  var basicAuthCredential = `${process.env.BACKEND_USERNAME}:${process.env.BACKEND_PASSWORD}`;
  var bace64 = btoa(basicAuthCredential);
  return axios.get(
    `${process.env.BACKEND_URL}/roles-from-email?email=${email}&_format=hal_json`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/hal+json',
        'X-CSRF-Token': `${process.env.BACKEND_CSRF_TOKEN}`,
        Authorization: 'Basic ' + bace64,
      },
    }
  );
}

export function get_backend_checkbox_options(field) {
  var basicAuthCredential = `${process.env.BACKEND_USERNAME}:${process.env.BACKEND_PASSWORD}`;
  var bace64 = btoa(basicAuthCredential);
  return axios.get(
    `${process.env.BACKEND_URL}/entity/field_storage_config/node.${field}?_format=hal_json`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/hal+json',
        'X-CSRF-Token': `${process.env.BACKEND_CSRF_TOKEN}`,
        Authorization: 'Basic ' + bace64,
      },
    }
  );
}

export function get_backend_select_options(field) {
  var basicAuthCredential = `${process.env.BACKEND_USERNAME}:${process.env.BACKEND_PASSWORD}`;
  var bace64 = btoa(basicAuthCredential);
  return axios.get(
    `${process.env.BACKEND_URL}/entity/field_storage_config/node.${field}?_format=hal_json`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/hal+json',
        'X-CSRF-Token': `${process.env.BACKEND_CSRF_TOKEN}`,
        Authorization: 'Basic ' + bace64,
      },
    }
  );
}

export function get_backend_all_county_options() {
  var basicAuthCredential = `${process.env.BACKEND_USERNAME}:${process.env.BACKEND_PASSWORD}`;
  var bace64 = btoa(basicAuthCredential);
  return axios.get(
    `${process.env.BACKEND_URL}/taxonomyterms/allcounties?_format=hal_json`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/hal+json',
        'X-CSRF-Token': `${process.env.BACKEND_CSRF_TOKEN}`,
        Authorization: 'Basic ' + bace64,
      },
    }
  );
}

export function save_to_backend(
  data,
  uid,
  DealerForecourt,
  TradeAverage,
  valueCalcFinal
) {
  var basicAuthCredential = `${process.env.BACKEND_USERNAME}:${process.env.BACKEND_PASSWORD}`;
  var bace64 = btoa(basicAuthCredential);
  return axios.post(
    `${process.env.BACKEND_URL}/entity/node?_format=hal_json`,
    JSON.stringify({
      _links: {
        type: {
          href: `${process.env.BACKEND_URL_NON_HTTPS}/rest/type/node/lead`,
        },
      },
      title: {
        value:
          data.carData.DataItems.VehicleRegistration.Vrm +
          ' ' +
          data.carData.DataItems.VehicleRegistration.YearOfManufacture +
          ' ' +
          data.carData.DataItems.VehicleRegistration.MakeModel,
      },
      field_registration_plate: {
        value: data.carData.DataItems.VehicleRegistration.Vrm,
      },
      field_lead_user: [
        {
          target_id: uid,
        },
      ],
      uid: [
        {
          target_id: uid,
        },
      ],
      field_lead_stage: [
        {
          target_id: 116,
        },
      ],
      field_make: [
        {
          target_id: data.makeid,
        },
      ],
      field_front_driver_side: [
        {
          target_id: 274,
        },
      ],
      field_back_driver_side: [
        {
          target_id: 274,
        },
      ],
      field_front_passenger_side: [
        {
          target_id: 274,
        },
      ],
      field_back_passenger_side: [
        {
          target_id: 274,
        },
      ],
      field_front_seats: [
        {
          target_id: 274,
        },
      ],
      field_rear_seats: [
        {
          target_id: 274,
        },
      ],
      field_dashboard: [
        {
          target_id: 274,
        },
      ],
      field_boot_interior: [
        {
          target_id: 274,
        },
      ],
      field_wheel_front_driver_side: [
        {
          target_id: 274,
        },
      ],
      field_wheel_rear_driver_side: [
        {
          target_id: 274,
        },
      ],
      field_wheel_front_passenger_side: [
        {
          target_id: 274,
        },
      ],
      field_wheel_rear_passenger_side: [
        {
          target_id: 274,
        },
      ],
      field_car_make_readable: {
        value: data.carData.DataItems.VehicleRegistration.Make,
      },
      field_model: {
        value: data.carData.DataItems.VehicleRegistration.Model,
      },
      field_mileage: {
        value: data.mileage,
      },
      field_year_of_manufacture: {
        value: data.carData.DataItems.VehicleRegistration.YearOfManufacture,
      },
      field_dealer_forecourt_valuation: {
        value: Math.round(DealerForecourt),
      },
      field_trade_average_valuation: {
        value: Math.round(TradeAverage),
      },
      field_calculated_valuation: {
        value: Math.round(valueCalcFinal),
      },
      field_vin: {
        value: data.carData.DataItems.VehicleRegistration.Vin,
      },
      field_colour: {
        value: data.carData.DataItems.VehicleRegistration.Colour,
      },
      field_engine_capacity: {
        value: data.carData.DataItems.VehicleRegistration.EngineCapacity,
      },
      field_fuel: {
        value: data.carData.DataItems.VehicleRegistration.FuelType,
      },
      ...(data.carData.DataItems.VehicleRegistration.TransmissionType
        ? {
            field_gearbox: {
              value: data.carData.DataItems.VehicleRegistration.TransmissionType.toLowerCase(),
            },
          }
        : {}),
      field_keepers: {
        value: data.carData.DataItems.VehicleHistory.NumberOfPreviousKeepers,
      },
      field_seating_plan: {
        value: data.carData.DataItems.VehicleRegistration.SeatingCapacity,
      },
      ...(data.description
        ? {
            field_car_description: {
              value: data.description,
            },
          }
        : {}),
      field_first_registered: {
        value: new Date(
          data.carData.DataItems.VehicleRegistration.DateFirstRegistered
        )
          .toISOString()
          .slice(0, 10),
      },
      ...(data.carData.DataItems.VehicleHistory.KeeperChangesList
        ? {
            field_last_keeper_change: {
              value: new Date(
                data.carData.DataItems.VehicleHistory.KeeperChangesList[0].DateOfLastKeeperChange
              )
                .toISOString()
                .slice(0, 10),
            },
          }
        : {}),
      ...(!data.carData.DataItems.VehicleHistory.KeeperChangesList
        ? {
            field_last_keeper_change: {
              value: '1900-01-01',
            },
          }
        : {}),
      ...(data.motExpiry
        ? {
            field_mot_expiry: {
              value: data.motExpiry,
            },
          }
        : {}),
      ...(!data.motExpiry
        ? {
            field_mot_expiry: {
              value: '1900-01-01',
            },
          }
        : {}),
      field_basic_details: data.field_basic_details,
      field_car_location: [
        {
          target_id: data.county,
        },
      ],
      field_what_colour_are_the_seats: {
        value: data.seatColor,
      },
      field_how_are_they_upholstered: {
        value: data.upholstered,
      },
      field_how_many_working_keys: {
        value: data.workingKeys,
      },
      field_do_you_have_the_v5c_logboo: {
        value: data.v5c,
      },
      field_is_the_v5c_logbook_in_your: {
        value: 'No',
      },
      field_do_you_have_the_book_pack_: {
        value: data.bookpack,
      },
      field_what_is_the_service_histor: {
        value: data.serviceHistory,
      },
      ...(data.numServices
        ? {
            field_how_many_services: {
              value: data.numServices,
            },
          }
        : {}),
      ...(data.mainDealerServices
        ? {
            field_how_many_main_dealer: {
              value: data.mainDealerServices,
            },
          }
        : {}),
      ...(data.lastServiceDate
        ? {
            field_when_was_the_vehicle_last: {
              value: data.lastServiceDate.toISOString().split('T')[0],
            },
          }
        : {}),
      ...(!data.lastServiceDate
        ? {
            field_when_was_the_vehicle_last: {
              value: '1900-01-01',
            },
          }
        : {}),
      ...(data.mileageAtLastService
        ? {
            field_what_was_the_mileage_at_th: {
              value: data.mileageAtLastService,
            },
          }
        : {}),
      ...(data.camBeltChanged
        ? {
            field_has_the_cambelt_ever_been: {
              value: data.camBeltChanged,
            },
          }
        : {}),
      field_bodywork_condition: {
        value: data.bodyworkCondition,
      },
      field_interior_condition: {
        value: data.interiorCondition,
      },
      field_warning_lights_on_dashboar: {
        value: data.warningLights,
      },
      ...(data.detailsIntOrExt
        ? {
            field_details_on_interior_or_ext: {
              value: data.detailsIntOrExt,
            },
          }
        : {}),
      field_alloy_wheel_condition: {
        value: data.alloyWheels,
      },
      field_tyre_condition: {
        value: data.tyreCondition,
      },
      field_do_you_have_the_locking_wh: {
        value: data.lockingWheel,
      },
      field_what_is_your_expected_amou: {
        value: data.expectedAmount,
      },
      field_is_the_vehicle_on_finance: {
        value: data.vehicleOnFinance,
      },
      field_is_this_vehicle_in_stock: {
        value: data.instock,
      },
      field_is_this_vehicle_a_customer: {
        value: data.customerscar,
      },
      field_lead_completed: {
        value: 'no',
      },
      type: {
        target_id: 'lead',
      },
    }),
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/hal+json',
        'X-CSRF-Token': `${process.env.BACKEND_CSRF_TOKEN}`,
        Authorization: 'Basic ' + bace64,
      },
    }
  );
}

export function upload_photo(filename, data, fieldname) {
  var basicAuthCredential = `${process.env.BACKEND_USERNAME}:${process.env.BACKEND_PASSWORD}`;
  var bace64 = btoa(basicAuthCredential);
  return axios.post(
    `${process.env.BACKEND_URL}/file/upload/node/lead/${fieldname}?_format=hal_json`,
    data,
    {
      headers: {
        'Content-Type': 'application/octet-stream',
        Accept: 'application/vnd.api+json',
        'Content-Disposition': `file; filename="${filename}"`,
        'X-CSRF-Token': `${process.env.BACKEND_CSRF_TOKEN}`,
        Authorization: 'Basic ' + bace64,
      },
    }
  );
}

export function attach_photo_to_node(nid, fieldname, fid) {
  var basicAuthCredential = `${process.env.BACKEND_USERNAME}:${process.env.BACKEND_PASSWORD}`;
  var bace64 = btoa(basicAuthCredential);
  return axios.patch(
    `${process.env.BACKEND_URL}/node/${nid}?_format=hal_json`,
    JSON.stringify({
      _links: {
        type: {
          href: `${process.env.BACKEND_URL_NON_HTTPS}/rest/type/node/lead`,
        },
      },
      [fieldname]: [
        {
          target_id: fid,
        },
      ],
      type: {
        target_id: 'lead',
      },
    }),
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/hal+json',
        'X-CSRF-Token': `${process.env.BACKEND_CSRF_TOKEN}`,
        Authorization: 'Basic ' + bace64,
      },
    }
  );
}

export function attach_multiple_photos_to_node(nid, fieldname, fid) {
  var basicAuthCredential = `${process.env.BACKEND_USERNAME}:${process.env.BACKEND_PASSWORD}`;
  var bace64 = btoa(basicAuthCredential);
  return axios.post(
    `${process.env.BACKEND_URL}/jsonapi/node/lead/${nid}/relationships/${fieldname}`,
    {
      data: [
        {
          type: 'file--file',
          id: fid,
        },
      ],
    },
    {
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'X-CSRF-Token': `${process.env.BACKEND_CSRF_TOKEN}`,
        Authorization: 'Basic ' + bace64,
      },
    }
  );
}

export function get_all_leads_for_user(uid) {
  var basicAuthCredential = `${process.env.BACKEND_USERNAME}:${process.env.BACKEND_PASSWORD}`;
  var bace64 = btoa(basicAuthCredential);
  return axios.get(
    `${process.env.BACKEND_URL}/rest-api/all_user_leads?_format=hal_json&uid=${uid}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/hal+json',
        'X-CSRF-Token': `${process.env.BACKEND_CSRF_TOKEN}`,
        Authorization: 'Basic ' + bace64,
      },
    }
  );
}

export function backend_update_email_preference(uid, status) {
  var basicAuthCredential = `${process.env.BACKEND_USERNAME}:${process.env.BACKEND_PASSWORD}`;
  var bace64 = btoa(basicAuthCredential);
  return axios.patch(
    `${process.env.BACKEND_URL}/user/${uid}?_format=hal_json`,
    JSON.stringify({
      _links: {
        type: {
          href: `${process.env.BACKEND_URL_NON_HTTPS}/rest/type/user/user`,
        },
      },
      field_agreed_to_emails: {
        value: status,
      },
    }),
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/hal+json',
        'X-CSRF-Token': `${process.env.BACKEND_CSRF_TOKEN}`,
        Authorization: 'Basic ' + bace64,
      },
    }
  );
}

export function backend_update_all_makes_select(uid, option) {
  var basicAuthCredential = `${process.env.BACKEND_USERNAME}:${process.env.BACKEND_PASSWORD}`;
  var bace64 = btoa(basicAuthCredential);
  return axios.patch(
    `${process.env.BACKEND_URL}/user/${uid}?_format=hal_json`,
    JSON.stringify({
      _links: {
        type: {
          href: `${process.env.BACKEND_URL_NON_HTTPS}/rest/type/user/user`,
        },
      },
      field_purchases_all_brands: {
        value: option,
      },
    }),
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/hal+json',
        'X-CSRF-Token': `${process.env.BACKEND_CSRF_TOKEN}`,
        Authorization: 'Basic ' + bace64,
      },
    }
  );
}

export function backend_update_makes_selected(uid, object) {
  var basicAuthCredential = `${process.env.BACKEND_USERNAME}:${process.env.BACKEND_PASSWORD}`;
  var bace64 = btoa(basicAuthCredential);
  return axios.patch(
    `${process.env.BACKEND_URL}/user/${uid}?_format=hal_json`,
    JSON.stringify({
      _links: {
        type: {
          href: `${process.env.BACKEND_URL_NON_HTTPS}/rest/type/user/user`,
        },
      },
      field_dealer_brands: [
        ...object.map(make => {
          return {
            target_id: make,
          };
        }),
      ],
    }),
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/hal+json',
        'X-CSRF-Token': `${process.env.BACKEND_CSRF_TOKEN}`,
        Authorization: 'Basic ' + bace64,
      },
    }
  );
}

export function backend_update_up_to_cost(uid, value) {
  var basicAuthCredential = `${process.env.BACKEND_USERNAME}:${process.env.BACKEND_PASSWORD}`;
  var bace64 = btoa(basicAuthCredential);
  return axios.patch(
    `${process.env.BACKEND_URL}/user/${uid}?_format=hal_json`,
    JSON.stringify({
      _links: {
        type: {
          href: `${process.env.BACKEND_URL_NON_HTTPS}/rest/type/user/user`,
        },
      },
      field_up_to_what_value_do_you_ty: {
        value: value,
      },
    }),
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/hal+json',
        'X-CSRF-Token': `${process.env.BACKEND_CSRF_TOKEN}`,
        Authorization: 'Basic ' + bace64,
      },
    }
  );
}

export function backend_update_age_range(uid, ageRange) {
  var basicAuthCredential = `${process.env.BACKEND_USERNAME}:${process.env.BACKEND_PASSWORD}`;
  var bace64 = btoa(basicAuthCredential);
  return axios.patch(
    `${process.env.BACKEND_URL}/user/${uid}?_format=hal_json`,
    JSON.stringify({
      _links: {
        type: {
          href: `${process.env.BACKEND_URL_NON_HTTPS}/rest/type/user/user`,
        },
      },
      field_purchases_from_year: {
        value: ageRange[0],
      },
      field_purchases_up_to_year: {
        value: ageRange[1],
      },
    }),
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/hal+json',
        'X-CSRF-Token': `${process.env.BACKEND_CSRF_TOKEN}`,
        Authorization: 'Basic ' + bace64,
      },
    }
  );
}

export function complete_lead(nid) {
  var basicAuthCredential = `${process.env.BACKEND_USERNAME}:${process.env.BACKEND_PASSWORD}`;
  var bace64 = btoa(basicAuthCredential);
  return axios({
    method: 'patch',
    url: `${process.env.BACKEND_URL}/node/${nid}?_format=hal_json`,
    data: JSON.stringify({
      _links: {
        type: {
          href: `${process.env.BACKEND_URL_NON_HTTPS}/rest/type/node/lead`,
        },
      },
      field_lead_completed: {
        value: 'yes',
      },
      type: {
        target_id: 'lead',
      },
    }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/hal+json',
      'X-CSRF-Token': `${process.env.BACKEND_CSRF_TOKEN}`,
      Authorization: 'Basic ' + bace64,
    },
  });
}

export function send_thank_you_email(data, status) {
  const enquiry = {
    first_name: atob(JSON.parse(localStorage['appState']).fnt),
    last_name: atob(JSON.parse(localStorage['appState']).lnt),
    email: atob(JSON.parse(localStorage['appState']).email),
    vechImg: '',
    telephone: JSON.parse(localStorage['appState']).tl
      ? atob(JSON.parse(localStorage['appState']).tl)
      : '',
    plate: data.field_registration_plate[0].value,
    makemodel:
      data.field_car_make_readable[0].value + ' ' + data.field_model[0].value,
    make: data.field_car_make_readable[0].value,
    model: data.field_model[0].value,
    year: data.field_year_of_manufacture[0].value,
    fuel: data.field_fuel[0].value,
    mileage: data.field_mileage[0].value,
    vin: data.field_vin[0].value,
    firstregistered: data.field_first_registered[0].value,
    color: data.field_colour[0].value,
    gearbox: data.field_gearbox[0].value,
    enginecapacity: data.field_engine_capacity[0].value,
    keepers: data.field_keepers[0].value,
    lastkeeperchange: data.field_last_keeper_change
      ? data.field_last_keeper_change[0].value
      : '',
    seating: data.field_seating_plan[0].value,
    description: data.field_car_description[0].value,
    status: status,
  };

  let uri = `${process.env.FE_RESOURCE_URL}/enquiry`;
  return axios.post(uri, enquiry);
}

export function get_first_name_last_name(uid) {
  var basicAuthCredential = `${process.env.BACKEND_USERNAME}:${process.env.BACKEND_PASSWORD}`;
  var bace64 = btoa(basicAuthCredential);
  return axios.get(`${process.env.BACKEND_URL}/user/${uid}?_format=hal_json`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/hal+json',
      'X-CSRF-Token': `${process.env.BACKEND_CSRF_TOKEN}`,
      Authorization: 'Basic ' + bace64,
    },
  });
}